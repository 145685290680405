<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติตัดสินค้าหมดอายุ / ชำรุด / เสียหาย : WH6-2
      </h3>
      <!-- <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button>
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มข้อมูล
        </button>
      </div> -->
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>ผู้ขออนุมัติตัดสต๊อก</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่าสินค้า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <!-- <td>
                <input
                  type="checkbox"
                  @change="isChecked(item)"
                  :checked="checkAll ? true : false"
                />
              </td> -->
              <td>
                {{ item.cut_off_no ? item.cut_off_no : "-" }}
              </td>
              <td>
                {{ item.user ? item.user.firstName : "" }}
                {{ item.user ? item.user.lastName : "" }}
              </td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>
              <td>{{ item.final_price }}</td>
              <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div @mousedown="hoverExportBtn()">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToExport(item, index)"
                  >
                    <div
                      :class="
                        !loadingExport
                          ? ''
                          : index == indexExport
                          ? 'spinner-grow spinner-grow-sm text-light'
                          : ''
                      "
                      role="status"
                    >
                      <i class="bi bi-printer-fill"></i>
                    </div>
                  </button>
                  <!-- <button
                  type="button"
                  class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                  @click="goToDelete()"
                >
                  <i class="bi bi-trash3-fill"></i>
                </button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import Pagination from "../../components/pagination.vue";
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Loader from "../../components/loaderIndex.vue";

import moment from "moment";

import pdfFile from "../../components/files/adjustStockFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "J&N | ตัดสินค้าหมดอายุ / ชำรุด / เสียหาย";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,
    dialogConfirmByPass: false,

    showing1: null,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.cutOffStock.getAll(1);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    goToNew() {
      this.$router.push({ path: "/warehouse/WH6-1/new", query: {} });
    },
    // goToEdit() {
    //   this.$router.push({
    //     name: "WH4-edit",
    //     query: {
    //       id: "123",
    //     },
    //   });
    // },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },

    //--- Pagination --- end
    // gotoImportExcel() {
    //   this.$router.push("/warehouse/WH4/importexcel");
    // },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.vendor_name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await purchaseApi.vendor.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let cutOffStockItems = this.itemApprove.cutOffStockItems;
      let updateResponse = [];
      let newQty = 0;
      let statusResponse = 1;

      await cutOffStockItems.forEach(async (element) => {
        if (element.product.status === "1") {
          newQty = element.product.product_bring_qty - element.item_amt;
          cutOffStockItems = {
            ...element.product,
            product_bring_qty: newQty,
          };
          updateResponse = await whApi.product.update(
            element.product.id,
            cutOffStockItems
          );
          newQty = 0;
          updateResponse.response_status === "SUCCESS"
            ? (statusResponse = statusResponse * 1)
            : (statusResponse = statusResponse * 0);
        } else {
          statusResponse = 0;
          this.validateProduct(element.product);
        }
      });
      if (statusResponse === 1) {
        this.approveCutOffStock(isApprove);
      }
    },
    validateProduct(product) {
      Swal.fire({
        icon: "error",
        title: `รายการสินค้า Barcode : &nbsp;<b>${product.product_barcode}</b>&nbsp; ยังไม่ได้รับการอนุมัติ ?`,
        confirmButtonText: "ตกลง",
        width: "500px",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({
            path: "/warehouse/WH6-4",
            query: {},
          });
        }
      });
    },
    async approveCutOffStock(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await whApi.cutOffStock.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    closeDialogImportExcel() {
      this.dialogImportExcel = false;
    },
    textSearch(val) {
      console.log({ val });
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");
        // const year = parseInt(oldDate.substring(6, 10)) + 543;
        // const newDate = moment(date).format(`DD/MM/${year}`);

        return oldDate;
      } else {
        return "-";
      }
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file

    async search() {
      this.loading = true;

      const responseSearch = await whApi.cutOffStock.search({
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
